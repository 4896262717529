import FormControl from '@gameonsports/components/cjs/FormControl'
import FormLabel from '@gameonsports/components/cjs/FormLabel'
import Input, { InputProps } from '@gameonsports/components/cjs/Input'
import Select, { SelectProps } from '@gameonsports/components/cjs/Select'
import { Field, FieldProps, FormikProps } from 'formik-1.5.8'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import aeFlag from './flags/ae.svg'
import atFlag from './flags/at.svg'
import auFlag from './flags/au.svg'
import beFlag from './flags/be.svg'
import caFlag from './flags/ca.svg'
import chFlag from './flags/ch.svg'
import coFlag from './flags/co.svg'
import czFlag from './flags/cz.svg'
import deFlag from './flags/de.svg'
import dkFlag from './flags/dk.svg'
import fiFlag from './flags/fi.svg'
import fjFlag from './flags/fj.svg'
import frFlag from './flags/fr.svg'
import gbFlag from './flags/gb.svg'
import hrFlag from './flags/hr.svg'
import huFlag from './flags/hu.svg'
import ieFlag from './flags/ie.svg'
import ilFlag from './flags/il.svg'
import jpFlag from './flags/jp.svg'
import nlFlag from './flags/nl.svg'
import noFlag from './flags/no.svg'
import nrFlag from './flags/nr.svg'
import nzFlag from './flags/nz.svg'
import plFlag from './flags/pl.svg'
import ruFlag from './flags/ru.svg'
import seFlag from './flags/se.svg'
import sgFlag from './flags/sg.svg'
import thFlag from './flags/th.svg'
import usFlag from './flags/us.svg'

const MobileFields = styled(FormControl)`
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  flex-shrink: 0;

  & > *:nth-child(3) {
    flex: 1;
    margin-left: 0.75rem;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 0.75rem;

    & > *:nth-child(3) {
      margin-left: initial;
    }
  }

  [class*='-error'] {
    flex-basis: 100%;
    grid-column: 1 / -1;
  }
`

const StyledFormLabel = styled(FormLabel)`
  width: 100%;
  grid-column: 1/ -1;
  margin-bottom: 0.5rem;
`

export const mobileCountryCodeValidation = (
  countryCodes: MobileNumberSupportedCountry[],
) =>
  Yup.string()
    .required('Mobile country code is required')
    .oneOf(
      countryCodes.map(c => c.mobileCountryCode),
      'Please select a country code from the list',
    )

interface MobileNumberValidationProps {
  mobileCountryCode: string
  supportedCountries: MobileNumberSupportedCountry[]
  schema: Yup.StringSchema
  optional?: boolean
}

export const mobileNumberValidation = ({
  mobileCountryCode,
  supportedCountries,
  schema,
  optional,
}: MobileNumberValidationProps) => {
  const selectedCountry: MobileNumberSupportedCountry | undefined =
    supportedCountries.find(
      country => country.mobileCountryCode === mobileCountryCode,
    )

  // AU validation
  if (selectedCountry?.alpha2Code === 'AU') {
    const addValidationSchema = (schema: Yup.StringSchema) => {
      return schema.test(
        'valid-au-mobile-number',
        'Mobile number must be 10 digits beginning with 04',
        (value: string) => {
          if (!value) {
            return optional ?? false
          }

          const trimmedValue = value.replace(/ /g, '')

          return (
            /^\d+$/.test(trimmedValue) &&
            trimmedValue.length === 10 &&
            trimmedValue.charAt(0) === '0' &&
            trimmedValue.charAt(1) === '4'
          )
        },
      )
    }

    if (optional) {
      return addValidationSchema(schema)
    }

    return addValidationSchema(schema.required('Mobile number is required'))
  }

  // International validation
  const addValidationSchema = (schema: Yup.StringSchema) => {
    return schema.test(
      'valid-international-mobile-number',
      'Please enter a valid mobile number',
      async (value: string) => {
        if (!value) {
          return optional ?? false
        }

        // We are intentially prepending the mobile country code to the value to ensure the
        // user does not input the country code into the free text field
        // i.e. A Canadian value of 118338858259 or +18338858259 should not pass validation
        return isValidPhoneNumber(
          `${mobileCountryCode}${value}`,
          (selectedCountry?.alpha2Code as CountryCode) ?? undefined,
        )
      },
    )
  }

  if (optional) {
    return addValidationSchema(schema)
  }

  return addValidationSchema(schema.required('Mobile number is required'))
}

export interface MobileNumberSupportedCountry {
  alpha2Code: string
  mobileCountryCode: string
  name: string
}

export interface MobileNumberFormValues {
  mobileAlpha2Code: string
  mobileCountryCode: string
  mobileNumber: string
}

interface MobileNumberProps {
  supportedCountries: MobileNumberSupportedCountry[]
  mobileAlpha2CodeProps?: Pick<
    SelectProps,
    'name' | 'error' | 'touched' | 'onBlur' | 'disabled' | 'data-testid'
  >
  mobileCountryCodeProps?: Pick<
    SelectProps,
    'name' | 'error' | 'touched' | 'onBlur' | 'disabled' | 'data-testid'
  >
  mobileNumberProps?: Pick<
    InputProps,
    'name' | 'error' | 'touched' | 'disabled' | 'data-testid'
  >
  label?: string
  optional?: boolean
}

export const getFlag = (alpha2Code: string) => {
  switch (alpha2Code) {
    case 'ae':
      return aeFlag
    case 'au':
      return auFlag
    case 'at':
      return atFlag
    case 'be':
      return beFlag
    case 'ca':
      return caFlag
    case 'ch':
      return chFlag
    case 'co':
      return coFlag
    case 'cz':
      return czFlag
    case 'de':
      return deFlag
    case 'dk':
      return dkFlag
    case 'fi':
      return fiFlag
    case 'fj':
      return fjFlag
    case 'fr':
      return frFlag
    case 'gb':
      return gbFlag
    case 'hr':
      return hrFlag
    case 'hu':
      return huFlag
    case 'ie':
      return ieFlag
    case 'il':
      return ilFlag
    case 'jp':
      return jpFlag
    case 'nl':
      return nlFlag
    case 'no':
      return noFlag
    case 'nr':
      return nrFlag
    case 'nz':
      return nzFlag
    case 'pl':
      return plFlag
    case 'ru':
      return ruFlag
    case 'se':
      return seFlag
    case 'sg':
      return sgFlag
    case 'th':
      return thFlag
    case 'us':
      return usFlag
    default:
      return ''
  }
}

const MobileNumberInput: React.FC<
  FormikProps<MobileNumberFormValues> & MobileNumberProps
> = ({
  touched,
  errors,
  setFieldValue,
  setTouched,
  supportedCountries,
  mobileAlpha2CodeProps = {
    name: 'mobileAlpha2Code',
    touched: touched.mobileAlpha2Code,
    error: errors.mobileAlpha2Code,
  },
  mobileCountryCodeProps = {
    name: 'mobileCountryCode',
    touched: touched.mobileCountryCode,
    error: errors.mobileCountryCode,
  },
  mobileNumberProps = {
    name: 'mobileNumber',
    touched: touched.mobileNumber,
    error: errors.mobileNumber,
  },
  label = 'Mobile number*',
  optional,
}) => {
  /**
   * Flag SVG assets obtained from this repo: https://www.npmjs.com/package/react-country-flag
   * We are not using this repo directly as the assets are hosted
   * on a CDN which we have no control over
   */
  const supportedCountryItems = supportedCountries.map(
    ({ name, mobileCountryCode, alpha2Code }) => ({
      name: `${name} (${mobileCountryCode})`,
      value: alpha2Code, // using alpha2Code as it is unique per country, whereas country codes are not (many countries use +1)
      displayName: (
        <img
          src={getFlag(alpha2Code.toLowerCase())}
          width="24"
          alt={`${alpha2Code} flag`}
        />
      ),
    }),
  )

  return (
    <MobileFields
      touched={mobileCountryCodeProps.touched || mobileNumberProps.touched}
      error={mobileCountryCodeProps.error || mobileNumberProps.error}
      errorId={`${mobileNumberProps.name}-error`}
    >
      <StyledFormLabel htmlFor={mobileNumberProps.name}>
        {label}
      </StyledFormLabel>
      <Field name={mobileAlpha2CodeProps.name}>
        {({ field }: FieldProps) => (
          <Select
            {...field}
            id={field.name}
            items={supportedCountryItems}
            label="Mobile number country code"
            onChange={value => {
              const selectedCountry = supportedCountries.find(
                country => country.alpha2Code === value,
              )

              setFieldValue(
                mobileCountryCodeProps.name,
                selectedCountry?.mobileCountryCode || '',
              )
              setFieldValue(
                mobileAlpha2CodeProps.name,
                selectedCountry?.alpha2Code || '',
              )
            }}
            onBlur={() =>
              setTouched({
                ...touched,
                [field.name]: true,
                [mobileCountryCodeProps.name]: true,
              })
            }
            required={optional !== true}
            hideLabel
            hideError
            data-testid={`${mobileAlpha2CodeProps.name}-field`}
            {...mobileCountryCodeProps}
          />
        )}
      </Field>
      <Field name={mobileNumberProps.name}>
        {({ field }: FieldProps) => (
          <Input
            {...field}
            id={field.name}
            type="tel"
            label="Mobile number"
            hideLabel
            hideError
            data-testid={`${mobileNumberProps.name}-field`}
            required={optional !== true}
            preventAutoComplete
            {...mobileNumberProps}
          />
        )}
      </Field>
    </MobileFields>
  )
}

export default MobileNumberInput
