import Auth from '@aws-amplify/auth'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import useSignIn from '@gameonsports/aws-amplify-hooks/cjs/useSignIn'
import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import Input from '@gameonsports/components/cjs/Input'
import Notification from '@gameonsports/components/cjs/Notification'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { Field, FieldProps, Formik } from 'formik'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import BoxContainer from '../../components/BoxContainer'
import { getLocale } from '../../utils/env'

const Container = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  ${mq.up('tablet')} {
    padding: 4.5rem 2rem;
  }
`

const StyledBox = styled(BoxContainer)`
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(auto, 30rem);
  grid-gap: 1.5rem;
  max-width: 40rem;
  padding: 1rem;
  width: 100%;

  ${mq.up('tablet')} {
    padding: 3rem 1rem;
    grid-gap: 2.5rem;
  }
`

const ResendBox = styled(Container)`
  ${mq.up('tablet')} {
    padding: 0;
    grid-gap: 0.75rem;
    margin-top: -1.5rem;
  }
`

const ResendText = styled(Text)`
  text-decoration: none;
  justify-self: center;
  align-self: center;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`
const FooterText = styled(Text)`
  font-size: 0.875rem;
`

const Form = styled.form`
  display: grid;
  grid-gap: 3.5rem;
`

const FieldsContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`

const HeadingContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  text-align: center;
  h1 {
    margin: 0;
  }
`

const FormContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`

const LockIcon = styled(Icon)`
  justify-self: center;
`

const getChallengeInstructions = (
  type: 'SOFTWARE_TOKEN_MFA' | 'SMS_MFA',
  deviceInfo: string,
): string => {
  switch (type) {
    case 'SOFTWARE_TOKEN_MFA':
      return 'Please confirm your account by entering the 6 digit code from your authentication app.'
    case 'SMS_MFA':
      return `Please confirm your account by entering the 6 digit code sent to the mobile number ending in 
      ${deviceInfo.replace('+', '')}.`
    default:
      return 'Please confirm your account by entering the 6 digit code'
  }
}

const MfaChallenge: React.FC<{
  user: CognitoUserInterface
  loginDetails: {
    email: string
    password: string
  }
  redirectPath: string
  confirmSignIn: typeof Auth.confirmSignIn
  mfaType: 'SOFTWARE_TOKEN_MFA' | 'SMS_MFA'
}> = ({ redirectPath, confirmSignIn, mfaType, loginDetails, ...args }) => {
  const [hasError, setHasError] = useState(false)
  const [resentMfa, setResentMfa] = useState(false)
  // Used to re-send the confirmation code
  const [signIn, result] = useSignIn()
  const { links } = getLocale()

  const user =
    (result.user as unknown as CognitoUserInterface | undefined) ?? args.user

  const deviceInfo: string =
    user.challengeParam['CODE_DELIVERY_DESTINATION'] ||
    user.challengeParam['FRIENDLY_DEVICE_NAME'] ||
    ''

  return (
    <Container>
      <Helmet title="Multi-factor Authentication Challenge" />
      <StyledBox>
        <HeadingContainer>
          <LockIcon size="56" name="lock-closed"></LockIcon>
          <Text size="32" weight="700" as="h1">
            Authenticate your account
          </Text>
        </HeadingContainer>
        <Text textAlign="center">
          {getChallengeInstructions(mfaType, deviceInfo)}
        </Text>
        <FormContainer>
          <Formik
            initialValues={{
              mfaCode: '',
            }}
            onSubmit={async values => {
              await confirmSignIn(
                user,
                values.mfaCode,
                // gotta cast to any because of course CognitoUserInterface defines challengeName as a string even though
                // confirmSignIn() expects a more restricted type.
                user.challengeName as any,
              )
                .then(() => {
                  // User is now logged in
                  window.location.assign(redirectPath)
                })
                .catch(() => {
                  setHasError(true)
                })
            }}
          >
            {formProps => (
              <Form method="post" noValidate onSubmit={formProps.handleSubmit}>
                <FieldsContainer>
                  {hasError && (
                    <Notification variant="error">
                      The code entered is incorrect or had timed out. Please
                      check the code and try entering again.
                    </Notification>
                  )}

                  <Field name="mfaCode">
                    {({ field }: FieldProps) => (
                      <Input
                        {...field}
                        id={field.name}
                        type="text"
                        pattern="[0-9]*"
                        label="6 digit code"
                        data-testid="mfaCode-field"
                        error={formProps.errors.mfaCode}
                        touched={formProps.touched.mfaCode}
                        preventAutoComplete
                        inputMode="numeric"
                        required
                        placeholder="_ _ _ _ _ _"
                        maxLength={6}
                      />
                    )}
                  </Field>
                </FieldsContainer>

                <FooterText textAlign="center">
                  If you need to reset your Multi Factor Authentication, please{' '}
                  <a href={links.contact}>contact the support team</a>.
                </FooterText>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={!formProps.values.mfaCode}
                  data-testid="submit-mfa-code"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </FormContainer>
        {mfaType === 'SMS_MFA' && (
          <ResendBox>
            <Text textAlign="center">Haven't received your code yet?</Text>

            {!resentMfa && (
              <ResendText
                color="darkGrey400"
                textAlign="center"
                onClick={() => {
                  setResentMfa(true)
                  return signIn(loginDetails.email, loginDetails.password)
                }}
              >
                Resend a new code
              </ResendText>
            )}

            {resentMfa && <Text textAlign="center">Code resent</Text>}
          </ResendBox>
        )}
      </StyledBox>
    </Container>
  )
}

export default MfaChallenge
