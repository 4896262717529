import { useLocation } from '@reach/router'
import { getLocationParams } from '../../../utils/location'

const urlPathPattern = /^\/[a-zA-Z0-9-/]+$/

/**
 * @name notAllowedPaths
 * @description Paths that are not allowed to be redirected to
 * in order to prevent unintended actions (like logging out or repeating a login)
 */
const notAllowedPaths = ['/login', '/logout']

/**
 * @name useRedirectPath
 * @description Passing around redirectUrl's and path's can be a bit of a pain
 * and it poses a security risk.
 *
 * This hook is designed to:
 * - Get the current path
 * - Validate a path/url being passed in as a query param
 * - Prevent redirecting to external websites and certain paths
 * @returns { getPathFromQueryParam, getCurrentPath, isQueryParamAPath }
 */
export const useRedirectPath = () => {
  const location = useLocation()
  const params = getLocationParams(location)

  const canRedirectToThisPath = (path: string) => {
    if (!isPathValid(path)) {
      return null
    }

    return path
  }

  const isPathValid = (path: string) => {
    if (!path) {
      return false
    }

    if (!urlPathPattern.test(path)) {
      return false
    }

    if (notAllowedPaths.some(notAllowed => path.includes(notAllowed))) {
      return false
    }

    return true
  }

  const getPathFromQueryParam = (query: string) => {
    if (!isQueryParamAPath(query)) {
      return null
    }

    const decodedParam = decodeURIComponent(params[query] ?? '')

    return canRedirectToThisPath(decodedParam)
  }

  const isQueryParamAPath = (query: string) =>
    !!params[query] && isPathValid(decodeURIComponent(params[query] ?? ''))

  const getCurrentPath = () => {
    return location.pathname
  }

  return {
    getPathFromQueryParam,
    getCurrentPath,
    isQueryParamAPath,
  }
}
