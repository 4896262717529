import Auth from '@aws-amplify/auth'
import Button from '@gameonsports/components/cjs/Button'
import { Stack } from '@gameonsports/components/cjs/StackV2'
import { Text } from '@gameonsports/components/cjs/TextV3'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EXTERNAL_LOGIN_PROVIDER_CALLBACK } from '../../../../constants/registrationPaths'
import useFeatureFlagOn from '../../../../hooks/useFeatureFlagOn'
import aflIDButton from './images/afl-id-button.png'

const AFLIDButton = styled(Button)`
  justify-self: center;
  align-self: center;
  background-color: black;
  max-width: 8rem;
  background-image: url(${aflIDButton});
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;

  &:hover,
  &:focus {
    color: transparent;
  }
`

export const AFLiDLoginButton = ({ redirect }: { redirect: string }) => {
  const aflIDLoginFFOn = useFeatureFlagOn('layup-afl-id-login')
  const [aflIDOverride, setAflIDOverride] = useState(false)

  useEffect(() => {
    // Want the ability to be able to test AFLID login button before it's turned on to everyone.
    // FF doesn't really work for that.
    setAflIDOverride(!!localStorage.getItem('aflIDLoginEnabled'))
  }, [])

  const shouldShowAFLIDButton = aflIDLoginFFOn || aflIDOverride

  if (!shouldShowAFLIDButton) {
    return null
  }

  return (
    <Stack marginTop="s" alignItems="center" gap="s">
      <Text>Or log in with</Text>
      <AFLIDButton
        data-testid="aflid-login"
        variant="primary"
        onClick={async () => {
          // Need a static URL for the external provider to redirect back to, so save the location that we
          // were going to redirect to and use it later
          localStorage.setItem(EXTERNAL_LOGIN_PROVIDER_CALLBACK, redirect)
          await Auth.federatedSignIn({
            provider: 'afl' as any,
          })
        }}
      >
        Sign in with AFL iD
      </AFLIDButton>
    </Stack>
  )
}

export default AFLiDLoginButton
